<script>

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components:{
    Loading
  },
  props: {
    step: String,
    artTypes: Array,
    bookingID: Number,
    activeStep: Number,
    totalSteps: Number
  },
  data(){
    return {
      isLoading: false,
      art_type: [],
      booking_art_types: [],
      art_types: []
    }
  },
  watch: {
    artTypes (val) {
      if (val) {
        this.art_type = val
      }
    }
  },
  computed: {
    bookingProgress() {
      return this.activeStep * 100 / this.totalSteps
    }
  },
  mounted() {
    this.isLoading = true
    this.$axios.get(`/v3/event_bookings/${this.bookingID}`)
      .then(response => {
        this.booking_art_types = response.data.booking_art_types
        this.art_type = response.data.art_types
        this.isLoading = false
      })
      .catch(error => {
        this.isLoading = false
      })

    this.$axios.get('/v3/art_types')
      .then(response => {
        this.art_types = response.data
        this.isLoading = false
      })
      .catch(error => {
        this.isLoading = false
      })
  },
  methods: {
    isArtTypeSelected(artType) {
      return this.art_type.findIndex(art_type => art_type.slug === artType.slug) >= 0;
    },
    artTypeDeselected(deselected_art_type) {
      let artTypeIndex = this.art_type.findIndex(art_type => art_type.slug === deselected_art_type.slug);
      if (artTypeIndex >= 0) {
        this.art_type.splice(artTypeIndex, 1);
        return true;
      }
      return false
    },
    artTypeSelected(selected_art_type) {
      if (this.artTypeDeselected(selected_art_type)) {
        return;
      } else {
        this.art_type.unshift(selected_art_type);
      }
    },
    nextStep() {
      let new_art_types = this.art_type.map(new_art_type => {
        let index = this.booking_art_types.findIndex(booking_art_type => booking_art_type.slug == new_art_type.slug)
        return {
          id: index >=0 ? this.booking_art_types[index].id : null,
          art_type_slug: new_art_type.slug,
        }
      })

      let removed_art_types = this.booking_art_types.map(booking_art_type => {
        let index = this.art_type.findIndex(new_art_type => new_art_type.slug == booking_art_type.slug)
        if (index < 0) {
          return {
            id: booking_art_type.id,
            art_type_slug: booking_art_type.slug,
            _destroy: true
          }
        }
      }).filter(item => item)
      let params = {
        booking:  {
          booking_art_types_attributes: new_art_types.concat(removed_art_types)
        }
      }

      this.$emit('updateStep', { booking_params: params, next_step: 'select_speciality' })
    }
  },
}
</script>

<template>
  <div class="new-booking__art-type new-booking__content">
    <div class="vld-parent">
      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#FF8141"
      ></loading>
    </div>

    <p class="mt-4 mb-5">
      Select which art types you are looking for
    </p>

    <div v-if="art_type.length > 0" class="mb-2">
      <h6 class="mb-3">Selected Art Types</h6>
      <ul class="selected-art_types-container">
        <li
          class="selected-art-type-item"
          v-for="(artType, index) in art_type"
          :key="index"
          @click="artTypeDeselected(artType)">
          <img class="art-type-icon" :src="artType.icon.small">
          {{ artType.name }}
          <span class="ms-auto">
            <font-awesome-icon :icon="['fas', 'circle-xmark']" style="color: #ff6161;" />
          </span>
        </li>
      </ul>
    </div>

    <div v-if="art_types.length > 0">
      <p class="mb-1">Art Types</p>

      <ul class="art_types-container">
        <li
          class="art-type-item"
          v-for="(art_type, index) in art_types"
          v-if="!isArtTypeSelected(art_type)"
          :key="index"
          @click="artTypeSelected(art_type)">
          <img class="art-type-icon" :src="art_type.icon.small">
            {{ art_type.name }}
        </li>
      </ul>
    </div>

    <div class="w-100 mt-5">
      <v-app class="w-100 mb-4">
        <p class="mb-1">Step {{ activeStep }} of {{ totalSteps }}</p>
        <v-progress-linear
          rounded
          :value="bookingProgress"
          height="8">
        </v-progress-linear>
      </v-app>
      <button class="settings-forms__btn btn primary fluid nxt-btn"
        @click="nextStep"
        :disabled="art_type.length <= 0">
        Next
      </button>
    </div>
  </div>
</template>
<style scoped lang="scss">
.option{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.custom-input-wrap{
  margin-bottom:16px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components:{
    Loading
  },
  props: {
    bookingID: Number,
    bookingArtTypes: Array,
    activeStep: Number,
    totalSteps: Number
  },
  data(){
    return {
      isShowSelectGenreScreen: false,
      isLoading: false,
      art_type: {},
      booking_art_types: this.bookingArtTypes || [],
      speciality_slugs: [],
      genre_slugs: [],
      specialities: [],
      filteredSpecialities: [],
      isSearchFilterActive: false,
      genres: [],
      art_type_data: {},
      current_index: 0,
      hideNextBtn: true
    }
  },
  mounted() {
    this.isLoading = true
    this.$axios.get(`/v3/event_bookings/${this.bookingID}`)
      .then(response => {
        this.booking_art_types = response.data.booking_art_types
        this.art_type = response.data.booking_art_types[0]
        this.current_index = 0
        response.data.booking_art_types.forEach(art_slug => {
          if (art_slug.slug === 'djs' || art_slug.slug === 'musicians') {
            this.isShowSelectGenreScreen = true
          }
          this.art_type_data = {
            ...this.art_type_data,
            [art_slug.slug]: { speciality_slugs: art_slug.specialities || [] }
          }
        })
        this.isLoading = false
      })
      .catch(error => {
        this.isLoading = false
      })
  },
  watch: {
    art_type (val) {
      this.isLoading = true
      this.$axios.get(`/v3/art_types/${val.slug}/specialities`)
        .then(response => {
          this.specialities = response.data
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
        })
      this.updateShowNextBtn()
    }
  },
  methods: {
    isSpecialitySelected(selected_speciality) {
      return this.art_type_data[this.art_type.slug].speciality_slugs.findIndex(speciality => speciality.slug === selected_speciality.slug) >= 0;
    },
    specialityDeselected(deselected_speciality) {
      let specialityIndex = this.art_type_data[this.art_type.slug].speciality_slugs.findIndex(speciality => speciality.slug === deselected_speciality.slug);
      if (specialityIndex >= 0) {
        this.art_type_data[this.art_type.slug].speciality_slugs.splice(specialityIndex, 1)
        this.updateShowNextBtn()
        return true;
      }
      return false
    },
    specialitySelected(selected_speciality) {
      if (this.specialityDeselected(selected_speciality)) {
        return;
      } else {
        this.art_type_data[this.art_type.slug].speciality_slugs.unshift(selected_speciality);
      }
      this.updateShowNextBtn()
    },
    bookingParams() {
      return  {
        booking: {
          booking_art_types_attributes: this.booking_art_types.map(art_type => {
            return {
              id: art_type.id,
              speciality_slugs: this.art_type_data[art_type.slug].speciality_slugs.map(speciality => speciality.slug),
              is_skip_speciality: this.art_type_data[art_type.slug].is_skip_speciality
            }
          })
        }
      }
    },
    updateStep() {
      this.art_type_data[this.art_type.slug].is_skip_speciality = false
      if (this.isShowSelectGenreScreen) {
        this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_genre'})
      } else {
        this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_date'})
      }
    },
    nextArtType() {
      this.art_type_data[this.art_type.slug].is_skip_speciality = false
      let old_index = this.current_index
      this.current_index = old_index + 1
      this.art_type = this.booking_art_types[old_index + 1]
    },
    previousArtType() {
      let old_index = this.current_index
      this.current_index = old_index - 1
      this.art_type = this.booking_art_types[old_index - 1]
    },
    back() {
      this.$emit('updateStep', { booking_params: this.bookingParams(), next_step: 'select_artist_type'})
    },
    searchSpecialities(event) {
      const searchTerm = event.target.value.toLowerCase()
      if (searchTerm !== '') {
        this.filteredSpecialities = this.specialities.filter(speciality => speciality.name.toLowerCase().includes(searchTerm))
        this.isSearchFilterActive = true
      } else {
        this.filteredSpecialities = []
        this.isSearchFilterActive = false
      }
    },
    selectSpeciality(option) {
      this.art_type_data[this.art_type.slug].speciality_slugs.push(option)
      this.updateShowNextBtn()
    },
    updateShowNextBtn() {
      this.hideNextBtn = !(this.art_type_data[this.art_type.slug].speciality_slugs.length > 0)
    }
  },
  computed: {
    bookingProgress() {
      return this.activeStep * 100 / this.totalSteps
    }
  }
}
</script>

<template>
  <div class="new-booking__art-type new-booking__content">
    <div class="vld-parent">
      <loading :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="true"
        color="#FF8141"
      ></loading>
    </div>
    <div class="complete-signup-header__title">
      What kind of <span class="cr-primary"> {{ art_type.name }}</span> are you looking for?
    </div>

    <p class="mt-4 mb-5">
      Select specialities you are looking for
    </p>

    <div v-if="art_type_data[art_type.slug] && art_type_data[art_type.slug].speciality_slugs.length > 0" class="mb-2">
      <h6 class="mb-3">Selected Specialities</h6>
      <ul class="selected-specialities-container">
        <li
          class="selected-speciality-item"
          v-for="(speciality, index) in art_type_data[art_type.slug].speciality_slugs"
          :key="index"
          @click="specialityDeselected(speciality)">
          {{ speciality.name }}
          <span class="ms-auto">
            <font-awesome-icon :icon="['fas', 'circle-xmark']" style="color: #ff6161;" />
          </span>
        </li>
      </ul>
    </div>

    <div v-if="specialities.length > 0">
      <p class="mb-2">Specialities</p>

      <input
        @keyup="searchSpecialities"
        type="text"
        class="input order-capture-input border-0 mb-5"
        placeholder="Search" >

      <ul v-if="isSearchFilterActive" class="specialities-container">
        <li
          class="speciality-item ms-2"
          v-for="(speciality, index) in filteredSpecialities"
          v-if="!isSpecialitySelected(speciality)"
          :key="index"
          @click="specialitySelected(speciality)">
          {{ speciality.name }}
        </li>
      </ul>
      <ul v-else class="specialities-container">
        <li
          class="speciality-item ms-2"
          v-for="(speciality, index) in specialities"
          v-if="!isSpecialitySelected(speciality)"
          :key="index"
          @click="specialitySelected(speciality)">
          {{ speciality.name }}
        </li>
      </ul>
    </div>

    <div class="mt-5">
      <v-app class="w-100 mb-4">
        <p class="mb-1">Step {{ activeStep }} of {{ totalSteps }}</p>
        <v-progress-linear
          rounded
          :value="bookingProgress"
          height="8">
        </v-progress-linear>
      </v-app>

      <div class="back-next">
        <button
          v-if="current_index > 0"
          class="settings-forms__btn btn secondary fluid"
          @click="previousArtType()">
          Back
        </button>
        <button
          v-if="current_index === 0"
          class="settings-forms__btn btn secondary fluid"
          @click="back()">
          Back
        </button>
        <button
          v-if="current_index < booking_art_types.length - 1"
          class="settings-forms__btn btn primary fluid"
          @click="nextArtType()"
          :disabled="hideNextBtn">
          Next
        </button>
        <button
          v-if="current_index === booking_art_types.length - 1"
          class="settings-forms__btn btn primary fluid"
          @click="updateStep()"
          :disabled="hideNextBtn">
          Next
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped>
.custom-input-wrap{
  margin-bottom:16px;
}
.multiselect {
    margin-bottom: 0px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
